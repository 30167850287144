import './DepthOverview.css';

import miniAppAsRectangle from './../../../assets/imgs/depth_overview_mini_app_as_rectangle.svg';
import miniAppAsRectangle2 from './../../../assets/imgs/depth_overview_mini_app_as_rectangle2.svg';
import marketplaceEmpty from './../../../assets/imgs/depth_overview_marketplace_empty.svg';
import marketplaceFull from './../../../assets/imgs/depth_overview_marketplace_full.svg';
import marketplaceAppSecondary from './../../../assets/imgs/depth_overview_marketplace_app_secondary.svg';
import marketplaceAppPrimary from './../../../assets/imgs/depth_overview_marketplace_app_primary.svg';
import subscribeBtn from './../../../assets/imgs/depth_overview_subscribe_but.svg';
import unsubscribeBtn from './../../../assets/imgs/depth_overview_unsubscribe_but.svg';
import workspaceMiniAppPrimaryMissing from './../../../assets/imgs/depth_overview_workspace_mini_app_primary_missing.svg';
import workspaceMiniAppPrimaryHighlighted from './../../../assets/imgs/depth_overview_workspace_mini_app_primary_highlighted.svg';
import workspaceMiniAppPrimaryComplete from './../../../assets/imgs/depth_overview_workspace_mini_app_primary_complete.svg';
import { useState, useEffect } from 'react';

function DepthOverview() {
    const displayInnerHeight1080p = 937;
    const displayInnerHeight = window.innerHeight * window.devicePixelRatio;
    const displayHeightProportion = displayInnerHeight/displayInnerHeight1080p;
    const displayInnerWidth1080p = 1920;
    const displayInnerWidth = window.innerWidth * window.devicePixelRatio;  //window.innerWidth * window.devicePixelRatio;
    const displayWidthProportion = displayInnerWidth/displayInnerWidth1080p;
    const [miniAppRectangleSize, setMiniAppRectangleSize] = useState(15*displayHeightProportion);
    const [miniAppRectangleTranslationPosition, setMiniAppRectangleTranslationPosition] = useState({ x: 0, y: 0 });
    const [miniAppRectangleOpacity, setMiniAppRectangleOpacity] = useState(1);

    const [miniAppRectangleScale2, setMiniAppRectangleScale2] = useState(0.2*displayHeightProportion);
    const [miniAppRectangleTranslationPosition2, setMiniAppRectangleTranslationPosition2] = useState({ x: 0, y: 0 });
    const [miniAppRectangleOpacity2, setMiniAppRectangleOpacity2] = useState(0);
    const [subscriptionTextScale, setSubscriptionTextScale] = useState(0.7);

    const [marketplaceEmptySize, setMarketplaceEmptySize] = useState(30);
    const [isMarketplaceEmptyDisplay, setIsMarketplaceEmptyDisplay] = useState(true);       //TODO: allows to replace individual elements with single picture after combination complete
    const yOffsetStep2 = 57*displayHeightProportion;
    const marketplaceAppPrimaryTranslationPosition = { x: -140, y: 65 }
    const marketplaceAppSecondaryTranslationPosition = { app1: { x: -140, y: 65 }, 
                                                        app2: { x: -140, y: 65+yOffsetStep2 }}
    const [marketplaceAppSecondaryTranslationYOffsetStep2, setMarketplaceAppSecondaryTranslationYOffsetStep2] = useState(0);

    const yOffsetStep3 = 525*displayHeightProportion;
    const [marketplaceAppSecondaryTranslationYOffsetStep3, setMarketplaceAppSecondaryTranslationYOffsetStep3] = useState(0);
    const [marketplaceAppPrimaryOpacity, setMarketplaceAppPrimaryOpacity] = useState(1);
    
    const [isSubBtnsDisplayed, setIsSubBtnsDisplayed] = useState(true);
    const subUnsubBtnsOffset = { x: 300, y: 50*displayHeightProportion };
    const [subUnsubBtnsYOffsetStep4, setSubUnsubBtnsYOffsetStep4] = useState(0);
    const [subscribeBtnOpacity, setSubscribeBtnOpacity] = useState(0);
    const [unsubscribeBtnOpacity, setUnsubscribeBtnOpacity] = useState(0);
    const subUnsubHoverPositionOffset = { x: -2, y: -2 };
    const [subUnsubHoverPositionOffsetApplied, setSubUnsubHoverPositionOffsetApplied] = useState({ x: 0, y: 0 });

    const [isWorkspaceMiniAppPrimaryMissingDisplayed, setIsWorkspaceMiniAppPrimaryMissingDisplayed] = useState(true);

    let oneTimeBool = true;

    useEffect(() => {
        const handleScroll = () => {
            // Calculate new font size based on scroll position
            //for cross device adjustments const windowWidth = window.innerWidth;
                //display of 1440p:
                //7200
                //10500
                
                const scrollStep0 = 5763*displayHeightProportion;   //4850;
                const scrollStep1 = (scrollStep0) + (490*displayHeightProportion);  //6253
                const scrollStep2 = (scrollStep1) + (140*displayHeightProportion);  //6393
                const scrollStep3 = (scrollStep2) + (327*displayHeightProportion);  //6740
                const scrollStep3_1 = (scrollStep2) + (440*displayHeightProportion);  //6833
                const scrollStep4 = (scrollStep3_1) + (82*displayHeightProportion);    //6915
                const scrollStep5 = (scrollStep4) + (550*displayHeightProportion);  //7575
                const scrollStep6 = (scrollStep5) + (375*displayHeightProportion);  //7600
                
                /*
                Ranges and animation data as documentation
                ===================================================================
                - mini app rectangle 15 -> 8rem
                - mini app rectangle position (x:0, y:0) -> (x:-470%, y:540px)
                - offset-y-step2: 57px  (for the two secondary apps when the primary app moves in)
                - offset-y-step3: 525px     (for the entire marketplace with the apps to move app upload section to subscribe section)
                ===================================================================
                */
            
            const scrollYValue = window.scrollY;
            console.log(scrollYValue);       //test scroll precise position
            // console.log("window hight: " + window.innerHeight);
            console.log("value of scrollStep0 " + scrollStep0);
            console.log("value of scrollStep1 " + scrollStep1);
            console.log("value of displayInnerHeight " + displayInnerHeight);
            console.log("value of displayHeightProportion " + displayHeightProportion);
            if (scrollYValue < scrollStep0) {
                console.log("inside first if");
                setMiniAppRectangleSize(15*displayHeightProportion);    //rem
                setMiniAppRectangleTranslationPosition({
                    x: 0,
                    y: 0
                });
                setMiniAppRectangleOpacity(1);
            }
            else if (scrollYValue >= scrollStep0 && scrollYValue <= scrollStep1) {
                setMiniAppRectangleSize((15 - ((scrollYValue-scrollStep0)/(scrollStep1-scrollStep0))*7)*displayHeightProportion);     //rem
                setMiniAppRectangleTranslationPosition({
                    x: -((Math.sin((((scrollYValue-scrollStep0)/(scrollStep1-scrollStep0))*Math.PI)-(Math.PI/2))+1)*(235*((displayWidthProportion+1)/2))),   //%
                    y: (((scrollYValue-scrollStep0)/(scrollStep1-scrollStep0))*(580*displayHeightProportion)) //px
                });
                setMiniAppRectangleOpacity(1 - ((scrollYValue-scrollStep0)/(scrollStep1-scrollStep0))*1);
                setMarketplaceAppPrimaryOpacity(0);
                setMarketplaceAppSecondaryTranslationYOffsetStep2(0);
            }
            else if (scrollYValue > scrollStep1 && scrollYValue <= scrollStep2) {
                setMiniAppRectangleOpacity(0);

                setMiniAppRectangleTranslationPosition({
                    x: -2*235*(1/displayWidthProportion),  //%
                    y: 580*displayHeightProportion      //px
                });
                setMarketplaceAppSecondaryTranslationYOffsetStep2(((scrollYValue-scrollStep1)/(scrollStep2-scrollStep1))*yOffsetStep2);   //px
                setMarketplaceAppPrimaryOpacity(((scrollYValue-scrollStep1)/(scrollStep2-scrollStep1))*1);

                setMarketplaceAppSecondaryTranslationYOffsetStep3(0);
            }
            else if (scrollYValue > scrollStep2 && scrollYValue <= scrollStep3) {
                setMarketplaceAppSecondaryTranslationYOffsetStep2(yOffsetStep2);      //px
                setMarketplaceAppPrimaryOpacity(1);

                setMarketplaceAppSecondaryTranslationYOffsetStep3(((scrollYValue-scrollStep2)/(scrollStep3_1-scrollStep2))*yOffsetStep3);  //px

                // if (isSubBtnsDisplayed) {
                // } else {
                // }
                setSubscribeBtnOpacity(0);
                setUnsubscribeBtnOpacity(0);
                setIsSubBtnsDisplayed(true);
            }
            else if (scrollYValue > scrollStep3 && scrollYValue <= scrollStep3_1) {
                setMarketplaceAppSecondaryTranslationYOffsetStep3(((scrollYValue-scrollStep2)/(scrollStep3_1-scrollStep2))*yOffsetStep3);  //px
                setSubscribeBtnOpacity(1);
                setUnsubscribeBtnOpacity(0);
                setMiniAppRectangleTranslationPosition2({
                    x: -2*85*((displayWidthProportion+1)/2),//-2*825,   //-2*920,  //%
                    y: -490*displayHeightProportion      //px-1650%, -350px
                });
                setMiniAppRectangleScale2(0.2*displayWidthProportion);
                setMiniAppRectangleOpacity2(0);

                setSubscriptionTextScale(0.7);
            }
            else if (scrollYValue > scrollStep3_1 && scrollYValue <= scrollStep4) {
                setMarketplaceAppSecondaryTranslationYOffsetStep3(yOffsetStep3);     //px
                

                console.log("messed up");

                setMiniAppRectangleTranslationPosition2({
                    x: ((-2*85) + ((Math.sin((((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*Math.PI)-(Math.PI/2))+1)*143.25))*((displayWidthProportion+1)/2),//865.6),   //%
                    y: (-490 + ((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*794)*displayHeightProportion //px
                    // x: 81.2,   //-2*920,  //%
                    // y: 283      //px
                });

                setMiniAppRectangleScale2((0.2 + ((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*1.23)*displayWidthProportion);
                setMiniAppRectangleOpacity2(((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*1);

                setSubscriptionTextScale((0.7 + ((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*0.3));
            }
            else if (scrollYValue > scrollStep4 && scrollYValue <= scrollStep5) {
                setIsSubBtnsDisplayed(false);
                setSubscribeBtnOpacity(0);
                setUnsubscribeBtnOpacity(1);

                setMiniAppRectangleTranslationPosition2({
                    x: ((-2*85) + ((Math.sin((((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*Math.PI)-(Math.PI/2))+1)*143.25))*((displayWidthProportion+1)/2),//865.6),   //%
                    y: (-490 + ((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*794)*displayHeightProportion //px
                    // x: 81.2,   //-2*920,  //%
                    // y: 283      //px
                });

                setMiniAppRectangleScale2((0.2 + ((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*1.23)*displayWidthProportion);     //rem
                setMiniAppRectangleOpacity2(((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*1);
                setIsWorkspaceMiniAppPrimaryMissingDisplayed(true);

                setSubscriptionTextScale((0.7 + ((scrollYValue-scrollStep3_1)/(scrollStep5-scrollStep3_1))*0.3));
            }

            else if (scrollYValue > scrollStep5 && scrollYValue <= scrollStep6) {
                setIsWorkspaceMiniAppPrimaryMissingDisplayed(true);
                setIsSubBtnsDisplayed(false);
                setSubscribeBtnOpacity(0);
                setUnsubscribeBtnOpacity(1);

                setMiniAppRectangleTranslationPosition2({
                    x: 2*58.25*((displayWidthProportion+1)/2),   //-2*920,  //%     116.5%
                    y: 304*displayHeightProportion      //px
                });

                setMiniAppRectangleScale2(1.43*displayWidthProportion);//13.9);
                setMiniAppRectangleOpacity2(1);

                setSubscriptionTextScale(1);

                
                // setMiniAppRectangleTranslationPosition2({
                    //     x: -2*920,  //%
                    //     y: (0)//((scrollYValue-scrollStep5)/(scrollStep6-scrollStep5))*500+1200) //px      //px
                    // });
            }
                
            else if (scrollYValue > scrollStep6) {
                
                setIsWorkspaceMiniAppPrimaryMissingDisplayed(false);
            }

        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const onSubUnsubBtnClick = () => {
        setIsSubBtnsDisplayed(prevState => !prevState);
        
        setSubscribeBtnOpacity(currentState => (1 - currentState));
        setUnsubscribeBtnOpacity(currentState => (1 - currentState));
    };

    const onSubUnsubBtnEnter = () => {
        setSubUnsubHoverPositionOffsetApplied({
            x: subUnsubHoverPositionOffset.x,
            y: subUnsubHoverPositionOffset.y
        });
    }

    const onSubUnsubBtnLeave = () => {
        setSubUnsubHoverPositionOffsetApplied({
            x: 0,
            y: 0
        });
    }

    return (
        <div className='depth-overview'>
            <div className='depth-overview-title'>
                So, how does it work?
            </div>
            <div className='depth-overview-content'>
                <div className='depth-overview-content-initial-integration'>
                    <p className='depth-overview-content-paragraph'>
                        It start with a SaaS startup or a company using pointhubjs to power their SaaS platform for a particular industry or niche.
                    </p>
                    <p className='depth-overview-content-paragraph'>
                        Let's call such startup DuckLink, a city management software for ducks.
                    </p>
                    <p className='depth-overview-content-paragraph'>
                        DuckLink now creates pointhubjs database standardized schemas for developers to build upon mini apps, 
                        empowering inter-app communication.
                    </p>
                </div>
                <div className='depth-overview-content-developer-app'>
                    <p className='depth-overview-content-paragraph depth-overview-content-paragraph-developer-app'>
                        Duck SaaS developers then create web mini apps using the pointhubjs developer package.
                    </p>
                    <img className='mini-app-as-rectangle' src={miniAppAsRectangle} style={{width: `${miniAppRectangleSize}rem`, transform: `translate(${miniAppRectangleTranslationPosition.x}%, ${miniAppRectangleTranslationPosition.y}px)`, opacity: `${miniAppRectangleOpacity}`}} />
                </div>

                <div className='depth-overview-content-marketplace-app-addition'>
                    <div className='marketplace-app-addition-animation'>
                        <img className='marketplace-empty' src={marketplaceEmpty} style={{transform: `translate(0, ${marketplaceAppSecondaryTranslationYOffsetStep3}px) scale(${displayHeightProportion})`}} />
                        <img className='marketplace-app app-primary' src={marketplaceAppPrimary} style={{opacity: `${marketplaceAppPrimaryOpacity}`, transform: `translate(${marketplaceAppPrimaryTranslationPosition.x}%, ${marketplaceAppPrimaryTranslationPosition.y+marketplaceAppSecondaryTranslationYOffsetStep3}px) scale(${displayHeightProportion})`}} />
                        <img className='marketplace-app app-secondary-1' src={marketplaceAppSecondary} style={{transform: `translate(${marketplaceAppSecondaryTranslationPosition.app1.x}%, ${marketplaceAppSecondaryTranslationPosition.app1.y+marketplaceAppSecondaryTranslationYOffsetStep2+marketplaceAppSecondaryTranslationYOffsetStep3}px) scale(${displayHeightProportion})`}} />
                        <img className='marketplace-app app-secondary-2' src={marketplaceAppSecondary} style={{transform: `translate(${marketplaceAppSecondaryTranslationPosition.app2.x}%, ${marketplaceAppSecondaryTranslationPosition.app2.y+marketplaceAppSecondaryTranslationYOffsetStep2+marketplaceAppSecondaryTranslationYOffsetStep3}px) scale(${displayHeightProportion})`}} />
                    </div>
                    <p className='depth-overview-content-paragraph depth-overview-content-paragraph-marketplace-app-addition'>
                        The mini app is uploaded to the DuckLink company's pointhubjs powered marketplace.
                    </p>
                </div>

                <div className='depth-overview-content-marketplace-app-subscription'>
                    <img className='marketplace-empty' src={marketplaceEmpty} style={{ opacity: 0 }} />
                    
                    
                    <img className='marketplace-app-primary-subscribe-unsubscribe' 
                        src={subscribeBtn} 
                        onClick={onSubUnsubBtnClick} 
                        onMouseEnter={onSubUnsubBtnEnter}
                        onMouseLeave={onSubUnsubBtnLeave}
                        style={{ 
                            visibility: subscribeBtnOpacity > 0 ? 'visible' : 'hidden', 
                            opacity: `${subscribeBtnOpacity}`, 
                            transform: `
                                translate(${subUnsubBtnsOffset.x+subUnsubHoverPositionOffsetApplied.x}%, 
                                ${subUnsubBtnsOffset.y-(yOffsetStep3-marketplaceAppSecondaryTranslationYOffsetStep3)+subUnsubHoverPositionOffsetApplied.y}px)
                                scale(${displayHeightProportion})
                            `
                        }}
                    />

                    <img className='marketplace-app-primary-subscribe-unsubscribe' 
                        src={unsubscribeBtn} 
                        onClick={onSubUnsubBtnClick} 
                        onMouseEnter={onSubUnsubBtnEnter}
                        onMouseLeave={onSubUnsubBtnLeave}
                        style={{ 
                            visibility: unsubscribeBtnOpacity > 0 ? 'visible' : 'hidden', 
                            opacity: `${unsubscribeBtnOpacity}`, 
                            transform: `
                                translate(${subUnsubBtnsOffset.x+subUnsubHoverPositionOffsetApplied.x}%, 
                                ${subUnsubBtnsOffset.y-(yOffsetStep3-marketplaceAppSecondaryTranslationYOffsetStep3)+subUnsubHoverPositionOffsetApplied.y}px)
                                scale(${displayHeightProportion})
                            `
                        }}
                    />
                    <p className='depth-overview-content-paragraph depth-overview-content-paragraph-marketplace-app-addition'>
                        {/* DuckLink's customers can then choose to subscribe and unsubscribe from mini apps. */}
                        DuckLink's customers choose to subscribe and unsubscribe from mini apps based on their needs.
                    </p>
                </div>

                <div className='depth-overview-content-workspace-mini-app-primary'>
                    <img className='mini-app-as-rectangle2' 
                        src={miniAppAsRectangle2}
                        style={{ 
                            display: isWorkspaceMiniAppPrimaryMissingDisplayed ? 'inline' : 'none', 
                            // width: `${miniAppRectangleSize2}rem`, 
                            transform: `translate(${miniAppRectangleTranslationPosition2.x}%, ${miniAppRectangleTranslationPosition2.y}px) scale(${miniAppRectangleScale2})`, 
                            opacity: `${miniAppRectangleOpacity2}` 
                        }} 
                    />
                    <p className='depth-overview-content-paragraph depth-overview-content-paragraph-workspace-mini-app-primary'
                        style={{ 
                            opacity: `${miniAppRectangleOpacity2}`,
                            transform: `scale(${subscriptionTextScale})`, 
                        }}
                    >
                        When customers subscribe to mini apps, the apps show up on the customer's workspace.
                    </p>
                    
                    { 
                        isWorkspaceMiniAppPrimaryMissingDisplayed ? 
                            <img className='workspace-mini-app-primary' src={workspaceMiniAppPrimaryMissing} style={{ opacity: 1 }} /> 
                            : 
                            <img className='workspace-mini-app-primary' src={workspaceMiniAppPrimaryHighlighted} style={{ opacity: 1 }} />
                    }
                    
                    <p className='depth-overview-content-paragraph depth-overview-content-paragraph-workspace-mini-app-primary'>
                        This way DuckLink's customers can tailor suit their workspace to their actual SaaS business needs.
                    </p>

                    <img className='workspace-mini-app-primary' src={workspaceMiniAppPrimaryComplete} style={{ opacity: 1 }} />

                    <p className='depth-overview-content-paragraph depth-overview-content-paragraph-workspace-mini-app-primary'>
                        pointhubjs enables mini apps to interact with each other, without developers explicitly making the apps specifically compatible with one another.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default DepthOverview;