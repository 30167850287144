import './AnimatedOverview.css';

import rightArrow from './../../../assets/imgs/arrow_right_type_bracket.png';
import leftArrow from './../../../assets/imgs/arrow_left_type_bracket.png';

import behindIndustries from './../../../assets/videos/attracting_innovation_5v.mp4';
import revivingThem from './../../../assets/videos/reviving_them_3v.mp4';
import attractingInnovation from './../../../assets/videos/attracting_innovation.mp4';
import businessModelAndRevenue from './../../../assets/videos/business_model_and_revenue_3v.mp4';
import aNewEra from './../../../assets/videos/a_new_era.mp4';
import maintainingTheStandard from './../../../assets/videos/maintaining_the_standard.mp4';

import workspaceMiniAppCommunication from './../../../assets/videos/workspace_miniapp_communication_5v.mp4';    //(sta)2.2-(mid)10.5

import ContentControllerDots from './ContentControllerDots';
import { useEffect, useRef, useState } from 'react';

import MagneticIndustryElements from './MagneticIndustryElements/MagneticIndustryElements2';


function AnimatedOverview() {
    const [selectedDotIndex, setSelectedDotIndex] = useState(0);
    // const [isNearCursor, setIsNearCursor] = useState(false);
    // const ref = useRef(null);

    const menuContent = [
        {
            title: 'The pointhubjs platform',
            paragraphContent: [
                'pointhubjs is an open-source platform for SaaS development and its usage.' +
                ' The platform includes mainly a marketplace for SaaS app distribution and workspaces for the use and work with the ' +
                'SaaS apps, but that\'s far from it.' ,
                'The pointhubjs platform aims to eliminate from SaaS creation, development and integration as much elements as possible that have ' +
                'nothing to do with the SaaS idea itself, empowering developers to save time and funds while reducing Time To Market ' +
                'and achieving better Product-Market Fit.' ,
                'Since the platform is open-source, it invites innovation and creativity, translating into better and long lasting ' +
                'products, clients will want ot use.'

                //---------- OLD VERSION OF TEXT ----------
                // 'pointhubjs is an open-source platform for SaaS development and its usage.' +
                // ' The platform includes mainly a marketplace for SaaS app distribution and workspaces for the use and work of the ' +
                // 'SaaS apps, but that\'s far from it.' ,
                // 'The pointhubjs platform tries to eliminate from SaaS creation and integration as much elements as possible that have ' +
                // 'nothing to do with the SaaS idea itself, empowering developers to save time and funds while reducing Time To Market ' +
                // 'and achieving better Product-Market Fit.' ,
                // 'Since the platform is open-source, it invites innovation and creativity, translating into better and long lasting ' +
                // 'products clients will want ot use.'

                //---------- EVEN OLDER VERSION OF TEXT ----------
                /* 70% outdated software keeps back https://www.lsretail.com/resources/is-outdated-technology-destroying-your-retail-business */
                /* Software has been a key ingredient in successful businesses for a long time now.  */
                
                // 'Software plays a crucial role in the success of businesses for a long time now. When software gets ' +
                // 'old and outdated, 70% of businesses report they tend to perform worse. Some of today\'s industries and niches ' +
                // 'are bottlenecked by software that just isn\'t up for it anymore.',

                // 'Such cracks can be seen in industries like: Private and Public Healthcare, Small Businesses Sector, Governmental and ' + 
                // 'Municipal Management (Smart Cities), Supply Chain Management, and other kinds of management software.',
            ],
            video: behindIndustries,
        },
        {
            title: 'Reviving Them',
            paragraphContent: [
                'pointhubjs makes it so incredibly intuitive to bring high tech innovation into an industry or a niche, ' +
                'but also to keep it that way for as long as web apps and JavaScript are technologically relevant.',
                
                'In other words, as long as humans will have business needs to be solved by management software, pointhubjs ' +
                'will bridge the gap for businesses to software that makes their business run faster, smoother and not look back anymore.',
            ],
            video: revivingThem,
        },
        {
            title: 'Attracting Innovation',
            paragraphContent: [
                'Innovation isn\'t cheap, but pointhubjs makes it so much more attractive for developers to build SaaS compared ' + 
                'to anything else.', 
                'If a startup or even individual techies deploy the pointhubjs platform on an industry or a niche, SaaS developers ' +
                'won\'t need to deal with back-end development. Meaning, it would be so much more cheaper and attractive to ' +
                'invest time and lesser funds on front-end SaaS development, compared to today\'s traditional SaaS development ' + 
                'and deployment.',
            ],
            video: attractingInnovation,
        },
        {
            title: 'The Marketplace',//'Business Models And Revenue',
            paragraphContent: [
                'The pointhubjs marketplace is where SaaS developers list their apps for businesses and SaaS users to subscribe to.',
                'SaaS apps could be set free of charge, sold by subscription or any other business model.' +
                ' Developers can profit out of their work as they would on mobile marketplace platforms, while SaaS users have ' +
                'a complete choice over the apps and features they need for themselves.',
                'Companies and organizations also can affordably and easily develop SaaS apps and tools for internal use to enable ' +
                'greater efficiency and comfort for employees.',
                'Overall, it\'s a great way to facilitate transactions of SaaS and enable meeting tailored business needs for clients.'
                // 'The pointhubjs platform is equipped with a marketplace, which is the go to business model for the use of the ' + 
                // 'platform.',
                // 'The marketplace component of pointhubjs, is where SaaS developers\' web apps will be hosted at. It\'s where ' +
                // 'business and SaaS users will go, to subscribe to web apps that suit their business needs, it\'s also where ' + 
                // 'they will unsubscribe from web apps in favor of newer, better and more tailored apps.' ,
                // 'But you know, we won\'t spoil the kinds of monetization combinations for customers one could utilize, but ' +
                // 'competition is key ;)',
            ],
            video: businessModelAndRevenue,
        },
        {
            title: 'The Workspace',//'Business Models And Revenue',
            paragraphContent: [
                'The pointhubjs workspace is where businesses and SaaS users interact with the apps they have subscribed to.' +
                ' Users can organize their purchased apps on the workspace and through multiple workspaces for optimal use.',
                'All apps communicate with one another by nature of the pointhubjs platform\'s architecture with no special development' +
                ' required from developers.',
                'For example, allowing to enter a search query on one app, for the query\'s results to be displayed' +
                ' on a completely different app, both developed by completely different independent developers.', 
                'And of course NO developer has access to users\' data.'
                // 'The pointhubjs platform is equipped with a marketplace, which is the go to business model for the use of the ' + 
                // 'platform.',
                // 'The marketplace component of pointhubjs, is where SaaS developers\' web apps will be hosted at. It\'s where ' +
                // 'business and SaaS users will go, to subscribe to web apps that suit their business needs, it\'s also where ' + 
                // 'they will unsubscribe from web apps in favor of newer, better and more tailored apps.' ,
                // 'But you know, we won\'t spoil the kinds of monetization combinations for customers one could utilize, but ' +
                // 'competition is key ;)',
            ],
            video: workspaceMiniAppCommunication,
        },
        {
            title: 'A New Era',
            paragraphContent: [
                'Those who deploy the pointhubjs platform on an industry or niche, won\'t take a hit as well.', 
                'pointhubjs is built to scale and facilitate different industry volumes and needs. So expect what you need to ' + 
                'already exist. And if not, then remember it\'s open source, you\'re welcome to contribute or not :)',
                'We\'ve already made the calculations, but here is a quick back of the envelope one: SaaS developers using the ' + 
                'platform can save 50% and 70% on larger apps, so taking a 5-15% from revenue as platform hosting fee would still be more ' +
                'than attractive for SaaS developers.', 
            ],
            video: aNewEra,
        },
        {
            title: 'Maintaining The Standard',
            paragraphContent: [
                'Deploying the pointhubjs platform on an industry or a niche, requires an important element of product management.', 
                'Choosing the right industry and selecting target personas, probably based on ROI, will directly correlate to ' + 
                'you\'re success in finding product market fit. It\'s a good thing experimenting using pointhubjs is cheap.' ,
                'Clone the repo, standardize by building DB schemas for your target industry and personas, deploy on a small scale ' + 
                'on AWS or some other cloud provider or even on you\'re own servers, and start experimenting.' ,
            ],
            video: maintainingTheStandard,
        },
    ];

    // const handleMouseMove = (e) => {
    //     const rect = ref.current.getBoundingClientRect();
    //     const distX = e.clientX - rect.left - rect.width /  2;
    //     const distY = e.clientY - rect.top - rect.height /  2;
    //     const distance = Math.sqrt(distX * distX + distY * distY);

    //     if (distance <=  100) { // Radius within which the element will be magnetized
    //         setIsNearCursor(true);
    //     } else {
    //         setIsNearCursor(false);
    //     }
    // };
    
    // useEffect(() => {
    //     window.addEventListener('mousemove', handleMouseMove);
    //     return () => {
    //         window.removeEventListener('mousemove', handleMouseMove);
    //     };
    // }, []);

    const handleControllerDotSelection = (selectedIndex) => {
        setSelectedDotIndex(selectedIndex);
    }

    const handleControllerArrowClick = (isLeft, isRight) => {
        if (isLeft) {
            setSelectedDotIndex(selectedDotIndex-1);
        }

        if (isRight) {
            setSelectedDotIndex(selectedDotIndex+1);
        }
    }

    
    return (
        <div className='animated-overview'>
            {/* <Cursor /> */}
            <div className='animated-overview-title'>
                {/* Let's dive in more */}
                {/* README and watch */}
                What is pointhubjs
            </div>

            <div className='animated-overview-content'>
                <div className='animated-overview-content-row animated-overview-content-industries-map'>
                    <MagneticIndustryElements />
                </div>

                <div className='animated-overview-content-row animated-overview-content-pointhubjs-features'>
                    <div className='animated-overview-content-row-text'>
                        <div className='animated-overview-content-row-text-title'>
                            {/* Behind Industry/ies, Reviving It/Them, Attract Innovation, A New Era,  */}
                            {menuContent[0].title}
                        </div>

                        <div className='animated-overview-content-row-text-body'>
                            {menuContent[0].paragraphContent.map((paragraphContentItem) => {
                                return (<p className='menu-controller-content-text'>
                                    {paragraphContentItem}
                                </p>);
                            })}
                        </div>
                    </div>

                    <div className='animated-overview-content-row-animation'>
                        <video className='animated-overview-content-row-animation-video-mp4' 
                            preload='auto'
                            controlsList='nodownload' 
                            x-webkit-airplay='allow'
                            crossOrigin='anonymous'
                            aria-label='Video'
                            autoPlay
                            loop
                            muted
                            controls={false}
                            key={selectedDotIndex}
                        >
                            <source src={menuContent[0].video} typeof='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <div className='animated-overview-content-row animated-overview-content-marketplace'>
                    <div className='animated-overview-content-row-animation'>
                        <video className='animated-overview-content-row-animation-video-mp4' 
                            preload='auto'
                            controlsList='nodownload' 
                            x-webkit-airplay='allow'
                            crossOrigin='anonymous'
                            aria-label='Video'
                            autoPlay
                            loop
                            muted
                            controls={false}
                            key={selectedDotIndex}
                        >
                            <source src={menuContent[3].video} typeof='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <div className='animated-overview-content-row-text'>
                        <div className='animated-overview-content-row-text-title'>
                            {/* Behind Industry/ies, Reviving It/Them, Attract Innovation, A New Era,  */}
                            {menuContent[3].title}
                        </div>

                        <div className='animated-overview-content-row-text-body'>
                            {menuContent[3].paragraphContent.map((paragraphContentItem) => {
                                return (<p className='menu-controller-content-text'>
                                    {paragraphContentItem}
                                </p>);
                            })}
                        </div>
                    </div>
                </div>

                <div className='animated-overview-content-row animated-overview-content-workspace'>
                    <div className='animated-overview-content-row-text'>
                        <div className='animated-overview-content-row-text-title'>
                            {/* Behind Industry/ies, Reviving It/Them, Attract Innovation, A New Era,  */}
                            {menuContent[4].title}
                        </div>

                        <div className='animated-overview-content-row-text-body'>
                            {menuContent[4].paragraphContent.map((paragraphContentItem) => {
                                return (<p className='menu-controller-content-text'>
                                    {paragraphContentItem}
                                </p>);
                            })}
                        </div>
                    </div>

                    <div className='animated-overview-content-row-animation'>
                        <video className='animated-overview-content-row-animation-video-mp4' 
                            preload='auto'
                            controlsList='nodownload' 
                            x-webkit-airplay='allow'
                            crossOrigin='anonymous'
                            aria-label='Video'
                            autoPlay
                            loop
                            muted
                            controls={false}
                            key={selectedDotIndex}
                        >
                            <source src={menuContent[4].video} typeof='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>

        </div>
    );

    return(
        <div className='animated-overview'>
            <div className='animated-overview-title'>
                {/* Let's dive in more */}
                README and watch
            </div>
            <div className='animated-overview-content'>
                <div className='menu-controller'>{/*grid 10 80 10*/}
                    <div className='menu-controller-left-arrow'>
                        {/*arrow left*/}
                        <span className={`menu-controller-arrow-display ${(selectedDotIndex === 0) ? 'menu-controller-arrow-non-display' : ''}`}
                            onClick={() => handleControllerArrowClick(true, false)}
                        >
                            <img className='menu-controller-left-arrow-img' 
                                src={leftArrow} 
                            />
                        </span>
                    </div>
                    <div className='menu-controller-content'>
                        <div className='menu-controller-content-title'>
                            {/* Behind Industry/ies, Reviving It/Them, Attract Innovation, A New Era,  */}
                            {menuContent[selectedDotIndex].title}
                        </div>

                        {menuContent[selectedDotIndex].paragraphContent.map((paragraphContentItem) => {
                            return (<p className='menu-controller-content-text'>
                                {paragraphContentItem}
                            </p>);
                        })}

                        {/*buttons: documentation, demo, marketplace*/}

                        <div className='menu-controller-content-page-dots'>
                            <ContentControllerDots numberOfDots={menuContent.length} parentSelectedDotIndex={selectedDotIndex} handleSelectedDotIndex={handleControllerDotSelection}/>
                        </div>
                    </div>
                    <div className='menu-controller-right-arrow'>
                        {/*arrow right*/}
                        <span className={`menu-controller-arrow-display ${(selectedDotIndex === (menuContent.length-1)) ? 'menu-controller-arrow-non-display' : ''}`}
                            onClick={() => handleControllerArrowClick(false, true)}
                        >
                            <img className='menu-controller-right-arrow-img' 
                                src={rightArrow} 
                            />
                        </span>
                    </div>
                </div>
                <div className='animation-overview-video'>
                    <video className='animation-overview-video-mp4' 
                        preload='auto'
                        controlsList='nodownload' 
                        x-webkit-airplay='allow'
                        crossOrigin='anonymous'
                        aria-label='Video'
                        autoPlay
                        loop
                        muted
                        controls={false}
                        key={selectedDotIndex}
                    >
                        <source src={menuContent[selectedDotIndex].video} typeof='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
}

export default AnimatedOverview