
import React, { useState, useEffect, useRef } from 'react';
import './MagneticIndustryElements.css';
import MagneticElement from '../../../magneticElement/MagneticElement';
import pointhubjsLogo from './../../../../assets/imgs/figma_logo_6.svg';

function MagneticIndustryElements2() {
    const parentContainerRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [paragraphEndFondSize, setParagraphEndFondSize] = useState(1.7);
    const [paragraphEndLogoSize, setParagraphLogoSize] = useState(10);

    useEffect(() => {
        const handleScroll = () => {
            // Calculate new font size based on scroll position
            //for cross device adjustments const windowWidth = window.innerWidth;
            const minScroll = 1950
            const maxScroll = 2550

            //font size is between 1.7 - 2.5
            const scrollYValue = window.scrollY;
            // console.log(scrollYValue);       //test scroll precise position
            if (scrollYValue < minScroll) {
                setParagraphEndFondSize(1.7);
                setParagraphLogoSize(10);
            }
            else if (scrollYValue >= minScroll && scrollYValue <= maxScroll) {
                setParagraphEndFondSize(1.7 + ((scrollYValue-minScroll)/(maxScroll-minScroll))*0.8);
                setParagraphLogoSize(10 + ((scrollYValue-minScroll)/(maxScroll-minScroll))*6)
            }
            else if (scrollYValue > maxScroll) {
                setParagraphEndFondSize(2.5);
                setParagraphLogoSize(16);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const healthcareIndustryInit = { position: {x: window.innerWidth/8, y: window.innerHeight/5}, magneticRadius: ((window.innerWidth+window.innerHeight)/14)};
    const supplyChainIndustryInit = { position: {x: window.innerWidth/4.2, y: window.innerHeight/2.8}, magneticRadius: ((window.innerWidth+window.innerHeight)/14)};
    const cityManagementIndustryInit = { position: {x: window.innerWidth/11, y: window.innerHeight/1.9}, magneticRadius: ((window.innerWidth+window.innerHeight)/14)};
    const financialIndustryInit = { position: {x: window.innerWidth/2.2, y: window.innerHeight/4}, magneticRadius: ((window.innerWidth+window.innerHeight)/14)};
    const insuranceIndustryInit = { position: {x: window.innerWidth/3.5, y: window.innerHeight/4.2}, magneticRadius: ((window.innerWidth+window.innerHeight)/14)};
    const educationIndustryInit = { position: {x: window.innerWidth/2.1, y: window.innerHeight/2.2}, magneticRadius: ((window.innerWidth+window.innerHeight)/14)};
    const businessManagementIndustryInit = { position: {x: window.innerWidth/2.9, y: window.innerHeight/1.9}, magneticRadius: ((window.innerWidth+window.innerHeight)/14)};
    
    
    const handleMouseMove = (event) => {
        // Calculate the cursor position relative to the document
        const CursorX = event.clientX - parentContainerRef.current.getBoundingClientRect().left;
        const CursorY = event.clientY - parentContainerRef.current.getBoundingClientRect().top;

        setCursorPosition({
            x: CursorX,
            y: CursorY
        });
    };
    //djofg hsfg saihg ojahsfgahfdog houas hgouashgfouahoghago ouag hoah gogoh gh gip hg
    return (
        <div className='container' ref={parentContainerRef} onMouseMove={handleMouseMove}>
            <div className='industry-overview-paragraph-div'>
                {/* Software is crucial for business success. More than 70% of business report significant negative impact because of outdated software.
                Here are just some of the industries pointhubjs can slingshot towards success.
                <br /> */}
                Software is crucial for business success, where more than 70% of businesses tend to perform worse because of outdated software.
                Here are just some of the industries experiencing this kind of impact.
            </div>
            <MagneticElement className={'animation-div'} elementInitPosition={healthcareIndustryInit.position} magneticRadius={healthcareIndustryInit.magneticRadius} cursorPosition={cursorPosition} >
                Healthcare
            </MagneticElement>
            
            <MagneticElement className={'animation-div'} elementInitPosition={supplyChainIndustryInit.position} magneticRadius={supplyChainIndustryInit.magneticRadius} cursorPosition={cursorPosition} elementWidth={190} >
                Supply Chain Management
            </MagneticElement>

            <MagneticElement className={'animation-div'} elementInitPosition={cityManagementIndustryInit.position} magneticRadius={cityManagementIndustryInit.magneticRadius} cursorPosition={cursorPosition} elementWidth={180} >
                Smart City Management
            </MagneticElement>

            <MagneticElement className={'animation-div'} elementInitPosition={financialIndustryInit.position} magneticRadius={financialIndustryInit.magneticRadius} cursorPosition={cursorPosition} elementWidth={180} >
                Financial
            </MagneticElement>

            <MagneticElement className={'animation-div'} elementInitPosition={insuranceIndustryInit.position} magneticRadius={insuranceIndustryInit.magneticRadius} cursorPosition={cursorPosition} elementWidth={180} >
                Insurance
            </MagneticElement>

            <MagneticElement className={'animation-div'} elementInitPosition={educationIndustryInit.position} magneticRadius={educationIndustryInit.magneticRadius} cursorPosition={cursorPosition} elementWidth={180} >
                Education
            </MagneticElement>

            <MagneticElement className={'animation-div'} elementInitPosition={businessManagementIndustryInit.position} magneticRadius={businessManagementIndustryInit.magneticRadius} cursorPosition={cursorPosition} elementWidth={180} >
                Business Management
            </MagneticElement>

            <div className='industry-overview-paragraph-end-div' style={{fontSize: `${paragraphEndFondSize}rem`}}>
                {/* pointhubjs is the perfect open-source solution to slingshot your business towards success. */}
                <span>
                    Choose&nbsp;
                </span>
                {/* pointhubjs */}
                <img className='industry-overview-paragraph-end-pointhubjs-logo' src={pointhubjsLogo} style={{width: `${paragraphEndLogoSize}rem`}} />
                <span>
                    &nbsp;to slingshot any business towards success!
                </span>
            </div>
        </div>
    );
}

export default MagneticIndustryElements2;