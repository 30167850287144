import { useState } from 'react';
import './HeaderBusinessValues.css';

import PersonaItemValueCard from './PersonaItemValueCard';

import reactIcon from './../../../assets/imgs/small_react_icon.png';
import angularIcon from './../../../assets/imgs/small_angular_icon.png';
import vanillajsIcon from './../../../assets/imgs/small_vanilla_js_icon.png';

function HeaderBusinessValues() {
    const [selectedPersonaIndex, setSelectedPersonaIndex] = useState(0);

    const PersonaItemList = [
        'Developer',
        'SaaS User',
        'Platform Startup',
    ];

    const developerItemValues = [
        {
            title: String.fromCodePoint(parseInt('&#x23F3;'.substr(3), 16)) + ' Save ' + String.fromCodePoint(parseInt('&#x1F4B0;'.substr(3), 16)),
            listOfTitleImages: [],
            textValue: 'Save 50% and up to 70% on SaaS development.',
            cardCompactFit: false,
        },
        {
            title: '',
            listOfTitleImages: [reactIcon, vanillajsIcon, angularIcon],
            textValue: 'Develop using any front-end you like.',
            cardCompactFit: false,
        },
        {
            title: 'Impact ' + String.fromCodePoint(parseInt('&#x1F4A5;'.substr(3), 16)),
            listOfTitleImages: [],
            textValue: 'No need for back-end development, rather focus on impacting.',
            cardCompactFit: false,
        },
        {
            title: 'Seamless ' + String.fromCodePoint(parseInt('&#x1F60E;'.substr(3), 16)),
            listOfTitleImages: [],
            textValue: 'Seamlessly integrate with other SaaS apps.',
            cardCompactFit: false,
        },
    ];

    const userItemValues = [
        {
            title: 'Workspaces',
            listOfTitleImages: [],
            textValue: 'Subscribe to apps and place them on your workspaces.',
            cardCompactFit: false,
        },
        {
            title: String.fromCodePoint(parseInt('&#x1F9F5;'.substr(3), 16)) + ' Tailored',
            listOfTitleImages: [],
            textValue: 'Subscribe and pay for the apps that you actually need.',
            cardCompactFit: false,
        },
        {
            title: 'Easy Swap' + String.fromCodePoint(parseInt('&#x1F4A9;'.substr(3), 16)),
            listOfTitleImages: [],
            textValue: 'Don\'t want or like an app, just unsubscribe and subscribe to another.',
            cardCompactFit: false,
        },
        {
            title: 'Safe & ' + String.fromCodePoint(parseInt('&#x1F512;'.substr(3), 16)),   // It\'s Your Data
            listOfTitleImages: [],
            textValue: 'Secured and in your control, app developers can\'t see your private data.',    //get paid for it or leave it as is
            cardCompactFit: false,
        },
    ];

    const platformStartupItemValues = [
        {
            title: 'Venture ' + String.fromCodePoint(parseInt('&#x1F680;'.substr(3), 16)),
            listOfTitleImages: [],
            textValue: 'Find outdated and lacking behind industries or niches, and go for it.',
            cardCompactFit: false,
        },
        {
            title: 'Open-Source ' + String.fromCodePoint(parseInt('&#x1F929;'.substr(3), 16)),
            listOfTitleImages: [],
            textValue: 'So do what you need to do.',
            cardCompactFit: false,
        },
        {
            title: 'Standardize Industries',
            listOfTitleImages: [],
            textValue: 'Be the leading SaaS provider in your field.',
            cardCompactFit: false,
        },
        {
            title: 'Marketplace' + String.fromCodePoint(parseInt('&#x1F607;'.substr(3), 16)),
            listOfTitleImages: [],
            textValue: 'Take a small cut from developers\' subscription revenue.',
            cardCompactFit: true,
        },
    ];

    const handleSelectPersona = (index) => {
        setSelectedPersonaIndex(index);
    }

    return(
        <div className='homepage-header-business-values'>
            <div className='homepage-header-business-values-title'>
                Who do you want to be, and what you'll get?
            </div>
            <div className='homepage-header-business-values-content'>
                <div className='homepage-header-business-values-content-personas'>
                    {PersonaItemList.map((personaItem, index) =>
                        <div key={index} className={`customer-persona-item ${(selectedPersonaIndex===index) ? "selected-persona-item" : "not-selected-persona-item"}`} onClick={() => handleSelectPersona(index)}>
                            {personaItem}
                        </div>
                    )}
                </div>
                
                <div className='homepage-header-persona-business-values'>
                    {selectedPersonaIndex === 0 ? 
                        developerItemValues.map((developerItemValue, index) => 
                            <PersonaItemValueCard key={index} valueItem={developerItemValue} />
                        )
                        : 
                        null
                    }
                    {selectedPersonaIndex === 1 ? 
                        userItemValues.map((userItemValue, index) => 
                            <PersonaItemValueCard key={index} valueItem={userItemValue} />
                        )
                        : 
                        null
                    }
                    {selectedPersonaIndex === 2 ? 
                        platformStartupItemValues.map((startupItemValue, index) => 
                            <PersonaItemValueCard key={index} valueItem={startupItemValue} />
                        )
                        : 
                        null
                    }
                </div>
            </div>
        </div>
    );
}

export default HeaderBusinessValues;