import { useState } from 'react';
import './ContactUs.css';

import linkedinIcon from './../../../assets/imgs/linkedin_icon.svg';

function ContactUs() {
    const [contactUsName, setContactUsName] = useState('');
    const [contactUsEmail, setContactUsEmail] = useState('');
    const [isChatCheckboxChecked, setIsChatCheckboxChecked] = useState(false);
    const [isContributeCheckboxChecked, setIsContributeCheckboxChecked] = useState(false);
    const [contactUsMessage, setContactUsMessage] = useState('');
    const [submittedForm, setSubmittedForm] = useState(false);

    const clearForm = () => {
        setSubmittedForm(true);
        setContactUsName('');
        setContactUsEmail('');
        setIsChatCheckboxChecked(false);
        setIsContributeCheckboxChecked(false);
        setContactUsMessage('');
    }

    const handleContactUsSubmit = (event) => {
        event.preventDefault();

        const formData = {
            "name": contactUsName,
            "email": contactUsEmail,
            "isChat": isChatCheckboxChecked,
            "isContribute": isContributeCheckboxChecked,
            "contactMsg": contactUsMessage
        };

        const url = 'https://formkeep.com/f/c5c3ac642836'; // Replace with your actual formkeep action url

        fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            'Accept': 'application/javascript',
            'Content-Type': 'application/json',
        },
        })
        .then((response) => response.json())
        .then((data) => {
            clearForm();
            // console.log('Form submission response:', data);
            // Handle response as needed
        })
        .catch((error) => {
            // console.error('Form submission error:', error);
            // Handle error as needed
        });
    }

    if (submittedForm) {
        return(
            <div className='contact-us'>
                <div className='contact-us-title'>
                    Thanks for joining pointhubjs :)
                </div>
                
                <div className='copy-right-and-contact-info'>
                    <p>
                        © 2024 pointhubjs all rights reserved.
                    </p>
                    <a href='https://www.linkedin.com/company/pointhubjs'>
                        <img className='linkedin-icon' src={linkedinIcon} style={{ transform: 'scale(1.7)' }} />
                    </a>
                </div>
            </div>
        )
    }
    else {
        return(
            <div className='contact-us'>
                <div className='contact-us-title'>
                    Chat with us and join our waitlist
                </div>
                <div className='contact-us-content'>
                    <form className='contact-us-content-form' onSubmit={handleContactUsSubmit} >
                        <div className='contact-us-content-name'>
                            <input type='text' 
                                className='contact-us-content-input contact-us-content-name-input' 
                                placeholder="What's your name"
                                maxLength={20}
                                value={contactUsName}
                                onChange={(e) => {
                                    setContactUsName(e.target.value.replace(/[^a-zA-Z ]/g, ''));
                                }}
                            />
                        </div>

                        <div className='contact-us-content-email'>
                            <input type='email' 
                                className='contact-us-content-input contact-us-content-email-input' 
                                placeholder='Your email address'
                                value={contactUsEmail}
                                onChange={(e) => {
                                    setContactUsEmail(e.target.value.replace(/[^a-zA-Z0-9@._%+-]/g, ''));
                                }}
                            />
                        </div>

                        <div className='contact-us-content-chat'
                            onClick={() => {setIsChatCheckboxChecked(!isChatCheckboxChecked)}}
                        >
                            <div className={`contact-us-checkbox 
                                            ${isChatCheckboxChecked ? 'contact-us-checkbox-checked' : 'contact-us-checkbox-unchecked'}`
                                        }
                            >
                                
                            </div>
                            <div className={`contact-us-label ${isChatCheckboxChecked ? 'contact-us-label-checked' : ''}`}>
                                Would you like to chat?
                            </div>
                        </div>

                        <div className='contact-us-content-contribute'
                            onClick={() => {setIsContributeCheckboxChecked(!isContributeCheckboxChecked)}}
                        >
                            <div className={`contact-us-checkbox 
                                            ${isContributeCheckboxChecked ? 'contact-us-checkbox-checked' : 'contact-us-checkbox-unchecked'}`
                                        }
                            >
                                
                            </div>
                            <div className={`contact-us-label ${isContributeCheckboxChecked ? 'contact-us-label-checked' : ''}`}>
                                I Would like to contribute :)
                            </div>
                        </div>

                        <div className={`contact-us-content-text-message ${(!(isChatCheckboxChecked || isContributeCheckboxChecked)) ? 'contact-us-content-text-message-disabled' : ''}`}>
                            <textarea rows={3}
                                className={`contact-us-content-input contact-us-content-text-message-textarea ${(!(isChatCheckboxChecked || isContributeCheckboxChecked)) ? 'contact-us-content-text-message-textarea-disabled' : ''}`} 
                                placeholder='Your message'
                                maxLength={500}
                                value={contactUsMessage}
                                onChange={(e) => {setContactUsMessage(e.target.value)}}
                            />
                            <div className='contact-us-content-text-message-char-counter'>
                                {contactUsMessage.length}/500
                            </div>
                        </div>

                        <div className='contact-us-content-submit'>
                            <input type="submit" 
                                className='contact-us-content-submit-btn'
                                value={`${(!(isChatCheckboxChecked || isContributeCheckboxChecked)) ? 'Join waitlist' : 'Let\'s talk'}`} 
                            />
                        </div>
                    </form>
                </div>

                <div className='copy-right-and-contact-info'>
                    <p>
                        © 2024 pointhubjs all rights reserved.
                    </p>
                    <a href='https://www.linkedin.com/company/pointhubjs'>
                        <img className='linkedin-icon' src={linkedinIcon} style={{ transform: 'scale(1.7)' }} />
                    </a>
                </div>
            </div>
        );
    }
}

export default ContactUs;