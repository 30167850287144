import React, { useState, useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';

function MagneticElement({ children , className, elementInitPosition, magneticRadius, cursorPosition, elementWidth = null, magneticIntensity = 2.5 }){
    const magneticElementRef = useRef(null);
    const [magneticElementPosition, setMagneticElementPosition] = useState({ x: elementInitPosition.x, y: elementInitPosition.y });

    const calculateDistance = (pos1, pos2) => {
        return Math.sqrt((pos2.x - pos1.x)*(pos2.x - pos1.x) + (pos2.y - pos1.y)*(pos2.y - pos1.y));
    }

    useEffect(() => {
        // Get the width and height of the animated.div
        const magneticElementDivDim = { 
            width: magneticElementRef.current ? magneticElementRef.current.offsetWidth : 0,
            height: magneticElementRef.current ? magneticElementRef.current.offsetHeight : 0
        };

        const cursorPositionRelativeToMagneticElementCenter = { 
            x: cursorPosition.x - (magneticElementDivDim.width/2),
            y: cursorPosition.y - (magneticElementDivDim.height/2)
        };

        const cursorMagneticElementDistance = calculateDistance(cursorPositionRelativeToMagneticElementCenter, elementInitPosition);
        //console.log("healthcare distance: " + healthcareDistance);
        if (cursorMagneticElementDistance < magneticRadius) {
            setMagneticElementPosition({
                x: elementInitPosition.x + (cursorPositionRelativeToMagneticElementCenter.x - elementInitPosition.x) / magneticIntensity,
                y: elementInitPosition.y + (cursorPositionRelativeToMagneticElementCenter.y - elementInitPosition.y) / magneticIntensity,
            });
        } else {
            setMagneticElementPosition({
                x: elementInitPosition.x,
                y: elementInitPosition.y,
            });
        }
      }, [cursorPosition]);

    const animationSprintProps = useSpring({
        to: { transform: `translate(${magneticElementPosition.x}px, ${magneticElementPosition.y}px)` },
        config: { duration: 200, tension: 20, friction: 60 }
    });

    return (
        <animated.div
            className={className}
            ref={magneticElementRef}
            style={{
                ...animationSprintProps,
                position: 'absolute',
                width: `${elementWidth}px`,
            }}
        >
            {children}
        </animated.div>
    );
}

export default MagneticElement;