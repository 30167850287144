import { useState, useEffect } from 'react';

/* UI examples
*   https://www.coba.ai/
*   https://www.ion.design/
*   https://nextjs.org/
*   https://www.dev-tools.ai/
*   https://amplitude.com/
*   https://www.topo.io/
*/

import './HomepageHeader.css';
import pointhubjsLogo from './../../assets/imgs/figma_logo_6.svg';
import pointhubjsHomepage2DMockup from './../../assets/imgs/homepage_2d_mockup_no_shadow_1.7v.png';

import { TypeAnimation } from 'react-type-animation';

function HomepageHeader() {
    
    //const [introTitleTextChangeIndex, setIntroTitleTextChangeIndex] = useState(0);
    //const [introTitleTextChange, setIntroTitleTextChange] = useState(introTitleTextChangeValues[0]);
    const intervalBetweenWrite = 2300;
    const introTitleTextChangeValuesWithInterval = [
        'develop', 
        intervalBetweenWrite,
        'collaborate on', 
        intervalBetweenWrite,
        'deploy', 
        intervalBetweenWrite,
        'market', 
        intervalBetweenWrite,
        'profit from',
        intervalBetweenWrite
    ];
    

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         const lengthIntroTitleTextChange = (introTitleTextChange.length - 1);
    //         if (lengthIntroTitleTextChange > 0) {
    //             setIntroTitleTextChange(...introTitleTextChange.slice(0, lengthIntroTitleTextChange));
    //         } else {

    //         }
    //     },200)
    //   },[introTitleTextChangeIndex]);

    // const timeoutId = setTimeout(()=>{
    //     s
    //     if (introTitleTextChangeIndex === (introTitleTextChangeValues.length -1)) {
    //         setIntroTitleTextChangeIndex(0);
    //     } else {
    //         setIntroTitleTextChangeIndex(introTitleTextChangeIndex + 1);
    //     }
    //     clearTimeout(timeoutId);
    // }, 2500);


    return (
        <div className='homepage-header' alt=''>
            <div className='top-nav-bar'>
                <img className='pointhubjs-logo' src={pointhubjsLogo} />
            </div>
            <div className='reverse-order-mobile'>
                <div className='homepage-header-intro'>
                    <h1 className='homepage-header-intro-title'>
                        <span>The way you'll want to&nbsp;</span>
                        <span>
                            <span className='span-typed-words'>
                            <TypeAnimation
                                sequence={introTitleTextChangeValuesWithInterval}
                                className='typed-words'
                                wrapper="span"
                                speed={40}
                                deletionSpeed={50}
                                cursor={false}
                                style={{/*borderBottom: '0.5vh solid', borderRadius: '2px', */
                                        WebkitBackgroundClip: 'text', 
                                        MozBackgroundClip: 'text', 
                                        WebkitTextFillColor: 'transparent', 
                                        mozTextFillColor: 'transparent', 
                                        backgroundImage: 'linear-gradient(45deg, #94618F 15%, #5f4d93 85%)'
                                    }}
                                repeat={Infinity}
                                />
                            </span>
                            &nbsp;web apps.
                        </span>
                    </h1>
                    <div className='homepage-header-intro-subtitle'>
                        The only open-source marketplace and workspace platform for any SaaS, startup or well based company.
                        {/*The only open-source platform that will make you notice how much less effort it takes to create and use SaaS.*/}
                    </div>
                </div>
                
                <img className='pointhubjs-homepage-2d-mockup' src={pointhubjsHomepage2DMockup} />

            </div>
        </div>
    );
}

//collaborate on
//deploy
//market
//profit from

export default HomepageHeader;