import './App.css';
import HomepageHeader from './components/header/HomepageHeader';
import HomepageContent from './components/content/HomepageContent';
import HomepageFooter from './components/footer/HomepageFooter';

function App() {
  return (
    <div className="App">
      <HomepageHeader />
      <HomepageContent />
      <HomepageFooter />
    </div>
  );
}

export default App;
