import './HomepageContent.css';
import HeaderBusinessValues from './personaBenefits/HeaderBusinessValues';
import AnimatedOverview from './animatedOverview/AnimatedOverview';
import DepthOverview from './depthOverview/DepthOverview';

function HomepageContent() {
    return(
        <div className="homepage-content">
            <div className='homepage-content-business-values-div'>
                <HeaderBusinessValues />
            </div>
            
            <div className='homepage-content-animated-overview-div'>
                {/* examples of personas */}
                <AnimatedOverview />
            </div>

            <div className='homepage-content-depth-overview-div'>
                <DepthOverview />
            </div>
        </div>
    );
}

export default HomepageContent;