import './HomepageFooter.css';

import ContactUs from './contactUs/ContactUs';

function HomepageFooter() {
    return(
        <div className='homepage-footer'>
            <ContactUs />
        </div>
    );
}

export default HomepageFooter;