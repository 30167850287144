import './PersonaItemValueCard.css';

function PersonaItemValueCard({valueItem}) {
    return(
        <div className='persona-item-value-card'>
            <div className='item-title-value'>
                {valueItem.title}
                {valueItem.listOfTitleImages.map((img, index) => 
                    <img className='item-title-value-img' src={img} />
                )}
            </div>
            <div className={`item-text-value ${(valueItem.cardCompactFit) ? "item-text-value-compact" : ""}`}>
                <div>
                {valueItem.textValue}
                </div>
            </div>
        </div>
    );
}

export default PersonaItemValueCard;